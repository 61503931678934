<template>
  <v-container fluid fill-width>
    <!--
    <v-row align="center" justify="center" v-if="loading">
      <v-progress-circular
          style="position: center"
          color="primary"
          width="20"
          size="150"
          indeterminate
      >
      </v-progress-circular>
    </v-row>
    -->

    <!-- HEADER PAGINATION -->
    <div v-if="!loading && !onSelected">
      <!--
      <v-row>
        <v-row v-if="!($router.currentRoute.name === 'Selected')" justify="end">

          <v-col md="3">
            <v-text-field :label="$t('project.search')" v-model="search"></v-text-field>
          </v-col>


          <v-col md="2">
            <v-select
                :label="$t('project.dataTable.itemsPerPage')"
            ></v-select>
          </v-col>
        </v-row>
      </v-row>
      -->

      <!--
     <v-row>
       <v-col>
         <v-pagination
             total-visible="7"
             @input="changePage"
             :value="getCurrentRequest.page+1"
             :length="pageCount"
             :total-visible="totalVisible"
         ></v-pagination>

         <v-slider
             v-if="pageCount > 1"
             thumb-label="always"
             ticks="always"
             :value="getCurrentRequest.page + 1"
             :max="pageCount"
             min="1"
             :tick-labels="pagingSteps"
             @input="changePage"
             step="1"
         ></v-slider>

        </v-col>
      </v-row>
           -->
    </div>

    <v-row>
      <!--
      <v-progress-circular
          v-if="getLoading"
          style="position: center"
          color="primary"
          width="20"
          size="150"
          indeterminate
      >
      </v-progress-circular>
      -->


      <v-card elevation="0" style="width: 100%">
        <!-- HEADER -->
        <v-card-title class="px-0">
          <v-container fluid class="pa-0">
            <v-row justify="end">
              <v-col class="ma-auto">
                <p class="ma-0">Seite {{ getCurrentRequest.page === 0 ? 1 : getCurrentRequest.page }} {{ pageCount > 1 ? `von ${pageCount - 1}` : '' }}</p>
              </v-col>
              <v-col class="py-0 text-right" cols="4">
                <v-text-field
                    v-if="$router.currentRoute.name !== 'Selected'"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('project.search')"
                    single-line
                    hide-details
                    @input="check"
                ></v-text-field>
              </v-col>

              <v-col class="py-0" cols="2" lg="1" xl="1">
                <v-select
                    :label="$t('project.dataTable.itemsPerPage')"
                    v-model="itemsPerPage"
                    @input="reloadFiles"
                    :items="itemsPerPageConfig"
                ></v-select>
              </v-col>

              <v-col v-if="pageCount > 1" class="py-0" cols="2" lg="1" xl="1">
                <v-autocomplete
                    label="Seite"
                    v-model="getCurrentRequest.page"
                    :items="pagingSteps"
                    @input="changePage"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <!-- IMAGE TABLE / CONTENT -->
        <v-data-table
            :key="page"
            :loading="getLoading"
            v-model="selected"
            :headers="headers.filter(header => header.selected || header.actions)"
            :search="search"
            :items-per-page="itemsPerPage"
            :items="getLoading ? [] : (onSelected ? selected : files)"
            :single-select="singleSelect"
            :server-items-length="onSelected ? selected.length : getTotal"
            item-key="id"
            hide-default-footer
            show-select
            class="elevation-2"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:options="updateRequest"
            @page-count="pageCount = $event"
        >
          <template v-if="onSelected" v-slot:header.data-table-select="{headers}">
            <v-checkbox color="gray" :ripple="false" class="mt-1" :input-value="toggle"
                        @click.native.prevent.stop.capture="toggleAll">
            </v-checkbox>
          </template>

          <template v-for="column in columns" #[`item.${column.value}`]="{item}">
            <div :key="column.value" v-if="item && values[item.id]">
              <template v-if="column.type === 'TEXT'">
                <p>{{ values[item.id][column.value] }}</p>
              </template>

              <template v-if="column.type === 'TEXT_BAG'">
                <v-chip v-for="element in (values[item.name] ? filterBlank(values[item.name][column.value]): [])"
                        :key="element">
                  {{ element }}
                </v-chip>
              </template>

              <template v-if="column.type === 'TEXT_SEQ'">
                <p v-if="values[item.id][column.value] && values[item.id][column.value].length">
                  {{ values[item.id][column.value][0] || '' }}</p>
              </template>

              <template v-if="column.type === 'OBJECT'">

              </template>

              <template v-if="column.type === 'OBJECT_SEQ'">

              </template>

              <template v-if="column.type === 'OBJECT_BAG'">

              </template>

              <template v-if="column.type === 'ALT_LANG_TEXT'">
                <p v-if="values[item.id][column.value] && values[item.id][column.value].length">
                  {{ values[item.id][column.value][0].value || '' }}</p>
              </template>
            </div>
          </template>


          <!--*

                <template v-slot:item.metadata.dc.subject="{item}">
                  <v-chip v-for="keyword in item" :key="keyword">{{ keyword }}</v-chip>
                </template>


                       <template v-slot:item.metadata.plus.CopyrightOwner.CopyrightOwnerName="{ item }">
                         <template v-if="item.metadata.plus">
                           <template v-if="item.metadata.plus.CopyrightOwner.length">
                         <v-chip v-for="owner in item.metadata.plus.CopyrightOwner" :key="owner.CopyrightOwnerName" v-if="owner.CopyrightOwnerName">{{ owner.CopyrightOwnerName }}</v-chip>
                         </template>
                       </template>
                       </template>


                       <template v-slot:item.metadata.dc.creator="{ item }">
                         <template v-if="item.metadata.dc">
                           <template v-if="item.metadata.dc.creator && item.metadata.dc.creator.length">
                             {{ item.metadata.dc.creator[0] }}
                           </template>
                         </template>
                       </template>

                       <template v-slot:item.metadata.Iptc4xmpCore.subjectCode="{ item }">
                         <v-chip v-for="subjectCode in ((item.metadata && item.metadata.dc) ? item.metadata.Iptc4xmpCore.subjectCode : null)" :key="subjectCode">{{ subjectCode }}</v-chip>
                       </template>

                       <template v-slot:item.info.channels="{ item }">
                         <v-chip v-for="channel in item.info.channels" :key="channel">{{ channel }}</v-chip>
                       </template>

                       <template v-slot:item.Iptc4xmpCore.description="{ item }">
                         <span v-html="item.Iptc4xmpCore.description">
                         </span>
                       </template>

               -->

          <template #item.info.type="{item}">
            <p>{{ item.info.type }}</p>
          </template>

          <template #item.info.fileName="{item}">
            <p>{{ item.info.fileName }}</p>
          </template>

          <template #item.info.uploadDate="{item}">
            <p>{{ item.info.uploadDate }}</p>
          </template>

          <template v-slot:item.thumbnail="{ item }">
            <PreviewOverlay
                @click.prevent=""
                v-if="item.galleryUrl && (item.info.type === 'IMAGE' || item.info.type === 'PDF')"
                :img="true"
                :key="item.info ? item.info.uploadDate : item.name"
                :src="webImages + item.galleryUrl + '?key=' + syncRefresh"
                :filename="item.info.fileName"
                :imgId="item.id"
            >
              <v-img class="pa-4" :key="item.info ? item.info.uploadDate : item.name"
                     :src="webImages + item.thumbUrl + '?key=' + syncRefresh" contain max-width="150"
                     max-height="150">
              </v-img>
            </PreviewOverlay>

            <PreviewOverlay
                @click.prevent=""
                v-else-if="item.info.type === 'IMAGE'"
                :img="true"
                :filename="item.info.fileName"
                :imgId="item.id"
            >
              <v-img class="pa-4" :src="'data:image/jpeg;base64,'+item.thumbnail" contain max-width="150"
                     max-height="150">
              </v-img>
            </PreviewOverlay>
          </template>

          <template #item.info.channels="{item}">
            <v-chip v-for="channel in item.info.channels" :key="channel.id">{{ channel }}</v-chip>
          </template>
          <template v-slot:item.info.actions="{ item }">
            <v-btn
                v-if="($router.currentRoute.name !== 'Deleted')"
                icon
                @click="onMarked({item, refresh: $router.currentRoute.name !== 'CurrentProject' ? true : false})">
              <v-icon :color="'#c69500'" style="padding-left: 5px"
                      v-text="item.info.marked ? 'mdi-star' : 'mdi-star-outline'"></v-icon>
            </v-btn>

            <v-btn
                v-if="($router.currentRoute.name !== 'Deleted')"
                @click="$router.push(`/projects/${id}/edit/${item.id}`)"
                icon>
              <v-icon>
                {{ 'mdi-pencil-box-outline' }}
              </v-icon>
            </v-btn>


            <download-menu :icon="true" :item="item"
                           v-if="($router.currentRoute.name !== 'Deleted') && (item.info.type === 'IMAGE'  && !item.info.fileName.endsWith('.svg'))">
              <v-icon>
                {{ 'mdi-download' }}
              </v-icon>
            </download-menu>

            <v-btn v-else-if="item.info.type" icon @click="downloadDocument(item)">
              <v-icon>
                {{ 'mdi-download' }}
              </v-icon>
            </v-btn>

            <v-btn v-if="($router.currentRoute.name !== 'Deleted')" @click="onSync(item.id)"
                   icon>
              <v-progress-circular indeterminate v-if="isSyncing(item.id)"
                                   width="2" size="20"></v-progress-circular>
              <v-icon v-else-if="isError(item.id)" color="error">
                {{ 'mdi-sync-alert' }}
              </v-icon>
              <v-icon v-else>
                {{ 'mdi-sync' }}
              </v-icon>
            </v-btn>
            <v-btn
                v-if="($router.currentRoute.name !== 'Deleted')"
                @click="archivingId = item.id; isArchived =  item.info.archived; archiving = true"
                icon>
              <v-icon>
                {{ item.info.archived ? 'mdi-archive-arrow-up-outline' : 'mdi-archive-outline' }}
              </v-icon>
            </v-btn>
            <v-snackbar
                v-model="archiving"
            >
              {{ item.info.archived ? $tc('restoring.question', 1) : $tc('archiving.question', 1) }}
              <template v-slot:action="{ attrs }">
                <v-btn
                    color="error"
                    text
                    v-bind="attrs"
                    @click="archiving = false"
                >
                  {{ $t('no') }}
                </v-btn>
                <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="archive(archivingId, isArchived)"
                >
                  {{ $t('yes') }}
                </v-btn>
              </template>
            </v-snackbar>

            <v-btn
                color="primary"
                @click="deletingId = item.id; isDeleted = item.info.deleted; deleting = true"
                icon>
              <v-icon>
                {{ $router.currentRoute.name === 'Deleted' ? 'mdi-delete-restore' : 'mdi-delete' }}
              </v-icon>
            </v-btn>

            <v-snackbar
                v-model="deleting"
            >
              {{ isDeleted ? $tc('restoring.question', 1) : $tc('deleting.question', 1) }}
              <template v-slot:action="{ attrs }">
                <v-btn
                    color="error"
                    text
                    v-bind="attrs"
                    @click="deleting = false; deletingId=''"
                >
                  {{ $t('no') }}
                </v-btn>
                <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="deleteF(deletingId, isDeleted)"
                >
                  {{ $t('yes') }}
                </v-btn>
              </template>
            </v-snackbar>

            <template v-if="$router.currentRoute.name === 'Deleted'">
              <v-btn
                  color="error"
                  @click="deletingId = item.id; isDeleted = item.info.deleted; permDeleting = true"
                  icon>
                <v-icon>
                  {{ 'mdi-delete' }}
                </v-icon>
              </v-btn>

              <v-snackbar
                  v-model="permDeleting"
              >
                {{ $tc('deleting.question', 1) }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="error"
                      text
                      v-bind="attrs"
                      @click="permDeleting = false; deletingId=''"
                  >
                    {{ $t('no') }}
                  </v-btn>
                  <v-btn
                      color="success"
                      text
                      v-bind="attrs"
                      @click="permDeleting=false; permanentDeleteFile(deletingId)"
                  >
                    {{ $t('yes') }}
                  </v-btn>
                </template>
              </v-snackbar>
            </template>
          </template>

          <!--
          <template v-slot:footer>
            <v-container fluid>
              <v-row justify="end">
                <v-col cols="2">
                  <v-select
                      :label="$t('project.dataTable.itemsPerPage')"
                      v-model="itemsPerPage"
                      @input="reloadFiles"
                      :items="itemsPerPageConfig"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
          -->
        </v-data-table>

        <!-- FOOTER -->
        <v-card-actions>
          <v-container fluid class="pa-0">
            <v-row justify="end">
              <v-col class="py-0" cols="2" lg="1" xl="1">
                <v-select
                    :label="$t('project.dataTable.itemsPerPage')"
                    v-model="itemsPerPage"
                    @input="reloadFiles"
                    :items="itemsPerPageConfig"
                ></v-select>
              </v-col>

              <v-col v-if="pageCount > 1" class="py-0" cols="2" lg="1" xl="1">
                <v-autocomplete
                    label="Seite"
                    v-model="getCurrentRequest.page"
                    :items="pagingSteps"
                    @input="changePage"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>

    <!-- FOOTER PAGINATION
    <div v-if="!loading && !onSelected">
      <v-row>
        <v-col>
          <v-pagination
              total-visible="7"
              @input="changePage"
              :value="getCurrentRequest.page+1"
              :length="pageCount"
              :total-visible="totalVisible"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    -->

    <v-snackbar
        v-model="toggleQuestion"
    >
      {{
        $t('toggleQuestion')
      }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="error"
            text
            v-bind="attrs"
            @click="toggleQuestion = false"
        >
          {{ $t('no') }}
        </v-btn>
        <v-btn
            color="success"
            text
            v-bind="attrs"
            @click="selected = [];  toggle = false; toggleQuestion = false;"
        >
          {{ $t('yes') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import DownloadMenu from "@/components/DownloadMenu";
import {itemsPerPage} from "../../../config"
import PreviewOverlay from "@/components/PreviewOverlay";

export default {
  name: "FileTable",
  components: {DownloadMenu, PreviewOverlay},
  props: ['id'],
  data() {
    return {
      search: undefined,

      sortBy: [],
      sortDesc: [],
      webImages: process.webimagesUrl,
      deletingId: '',
      permDeleting: false,
      singleSelect: false,
      pageCount: 0,
      hide: false,
      page: 1,
      deleting: false,
      archiving: false,
      archivingId: '',
      isArchived: false,
      isDeleted: false,
      imageData: {},
      itemsPerPageConfig: itemsPerPage,
      totalVisible: 15,
      loading: false,
      headers: [],
      values: {},
      preview: {
        imgId: null,
        url: '',
        fileName: ''
      },
      show: false,
      toggle: false,
      toggleQuestion: false,
    }
  },
  async mounted() {
    this.toggle = !!this.selected.length;

    this.headers = this.columns.map(column => {
      if (column.actions) {
        return {
          text: column.text[this.getLocale] || column.text['en-EN'],
          divider: true,
          sortable: column.sortable,
          value: column.value,
          actions: column.actions
        }
      } else {
        return {
          text: column.text[this.getLocale] || column.text['en-EN'],
          divider: true,
          sortable: column.sortable,
          value: column.value,
          selected: column.selected
        }
      }
    });

    if (this.onSelected) {
      await this.prepareValues(this.selected)
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'main/getLoading',
      getSyncing: 'main/getSyncing',
      getSyncError: 'main/getSyncError',
      columns: 'main/getColumns',
      getSelected: 'main/getSelected',
      getCurrentRequest: 'main/getCurrentRequest',
      getItemsPerPage: 'main/getItemsPerPage',
      getTotal: 'main/getTotal',
      getLocale: 'main/getLocale',
      isLoading: 'main/isLoading',
      getConfig: 'main/getConfig',
      getDocumentConfig: 'main/getDocumentConfig',
      syncRefresh: 'main/getSyncRefresh',
      files: 'main/getFiles'
    }),
    pagingSteps() {
      const labels = [];

      for (let i = 1; i <= this.pageCount - 1; i++) {
        labels.push(i);
      }

      return labels;
    },
    onSelected() {
      return (this.$router.currentRoute.name === 'Selected')
    },
    selected: {
      get() {
        return this.getSelected
      },
      set(value) {
        this.setSelected(value)
      }
    },
    itemsPerPage: {
      set(value) {
        this.setItemsPerPage(value)
      },
      get() {
        return this.getItemsPerPage
      }
    },
    isError() {
      return (id) => this.getSyncError.find(sId => sId === id)
    },
    isSyncing() {
      return (id) => this.getSyncing.find(sId => sId === id)
    }
  },
  watch: {
    itemsPerPage() {
      this.changePage(1)
    },
    getLocale() {
      /*
      this.headers = this.columns.map(column => {
        if (column.actions) {
          return {
            text: column.text[this.getLocale] || column.text['en-EN'],
            divider: true,
            sortable: column.sortable,
            value: column.value,
            actions: column.actions
          }
        } else {
          return {
            text: column.text[this.getLocale] || column.text['en-EN'],
            divider: true,
            sortable: column.sortable,
            value: column.value,
            selected: column.selected
          }
        }
      })
       */
    },
    'columns': {
      handler(value) {
        this.headers = this.columns.map(column => {
          if (column.actions) {
            return {
              text: column.text[this.getLocale] || column.text['en-EN'],
              divider: true,
              sortable: column.sortable,
              value: column.value,
              actions: column.actions,
              width: '180px'
            }
          } else {
            return {
              text: column.text[this.getLocale] || column.text['en-EN'],
              divider: true,
              sortable: column.sortable,
              value: column.value,
              selected: column.selected
            }
          }
        })
      },
      deep: true
    },
    async files({length}) {
      if (length !== 0) {
        await this.prepareValues(this.files)
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapActions({
      loadPreview: 'main/loadPreview',
      sync: 'main/sync',
      permanentDeleteFile: 'main/permanentDeleteFile',
      onMarked: 'main/onMarked',
      loadFiles: 'main/getFiles',
      deleteFile: 'main/deleteFile',
      addToArchive: 'main/addToArchive',
      restoreFromArchive: 'main/restoreFromArchive',
      restoreFile: 'main/restoreFile',
      loadConfiguration: 'main/loadConfiguration',
      configParse: 'main/configParse',
      parseFile: 'main/parseFileMetadata',
      index: 'main/index',
      loadColumns: 'main/loadColumns',
      downloadDocument: 'main/downloadItems'
    }),
    ...mapMutations({
      setSelected: 'main/setSelected',
      setSyncing: 'main/setSyncing',
      addSyncing: 'main/addSyncing',
      setCurrentRequest: 'main/setCurrentRequest',
      setItemsPerPage: 'main/setItemsPerPage',
      setEditMetadata: 'main/setEditMetadata'
    }),
    isBlank(str) {
      return (!str || /^\s*$/.test(str));
    },
    filterBlank(arr) {
      if (Array.isArray(arr)) {
        return arr.filter(item => {
          return !this.isBlank(item)
        })
      } else {
        return this.isBlank(arr) ? [] : arr
      }
    },
    onSync(id) {
      this.addSyncing(id);
      this.sync(id)
    },
    toggleAll() {
      if (this.toggle) this.toggleQuestion = true
    },
    onSelect(obj) {
      obj.item.info.selected = obj.value
    },
    showPreview(item) {
      this.loadPreview(item.id)
      this.show = true
      this.preview = {
        imgId: item.id,
        url: '',
        fileName: item.info.fileName
      }
    },
    changePage(value) {
      let request = this.getCurrentRequest
      request.page = value;

      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setCurrentRequest(request);
        this.loadFiles();
      }, 700)
    },
    reloadFiles(value) {
      let request = this.getCurrentRequest
      request.size = this.itemsPerPage
      //request.size = value
      this.setCurrentRequest(request)
    },
    updateRequest({sortBy, sortDesc}) {
      if (this.sortBy[0] !== sortBy[0] || this.sortDesc[0] !== sortDesc[0]) {
        let request = this.getCurrentRequest
        if (sortBy.length && (sortBy[0] != this.sortBy || sortDesc[0] != this.sortDesc)) {
          this.sortBy = sortBy
          this.sortDesc = sortDesc
          request.sortList = [
            {
              asc: !sortDesc[0],
              fieldName: sortBy[0]
            }
          ]
          this.setCurrentRequest(request)
          this.loadFiles()
        } else {
          this.sortBy = []
          this.sortDesc = []
          request.sortList = []
          this.setCurrentRequest(request)
        }
      }
    },
    deleteF(id, isDeleted) {
      this.deleting = false;
      if (isDeleted) {
        this.restoreFile(id)
      } else {
        this.deleteFile(id)
      }
    },
    archive(id, isArchived) {
      this.archiving = false
      if (isArchived) {
        this.restoreFromArchive(id)
      } else {
        this.addToArchive(id)
      }
    },

    check() {
      window.clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        let request = this.getCurrentRequest
        request.search = this.search ? {'info.fileName': this.search, 'metadata.dc.title.value': this.search} : null
        this.setCurrentRequest(request)
        this.loadFiles()
      }, 700)
    },
    async prepareValues(files) {
      this.loading = true
      if (files && this.columns) {
        if (files.length) {
          for (const file of files) {
            this.values[file.id] = {}
            for (const column of this.columns.slice(4)) {
              this.values[file.id][column.value] = await this.index({obj: file, is: column.value})
            }
          }
          this.loading = false
        }
      }
      this.loading = false
    },
  }
}
</script>

<style lang="sass" scoped>
.v-data-table
  border-radius: 0px

.v-chip
  margin: 2px
</style>